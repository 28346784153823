import {handleErrors} from "@/utils/errors";
import CustomStore, {Options as CustomStoreOptions} from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import {alert, custom} from "devextreme/ui/dialog";
import {usePiniaStore} from "@/store";
import * as Sentry from "@sentry/vue";

// Utility function to check if XDEBUG is enabled in development
const addXDebugSession = (url: URL): void => {
    if (import.meta.env.MODE === "development" && import.meta.env.VITE_VEC_DEBUG === "1") {
        url.searchParams.set("XDEBUG_SESSION_START", "start");
    }
};

// Captcha rendering logic extracted into a separate function
const showCaptchaOverlay = async (): Promise<void> => {
    const overlay = custom({
        title: 'Captcha',
        messageHtml: '<div id="turnstile_widget_xhr"></div>',
        buttons: [
            {
                text: 'Überprüfung läuft...',
                icon: 'lock',
                disabled: true // prevent user interaction
            }
        ]
    });
    overlay.show();

    return new Promise((resolve, reject) => {
        turnstile.render('#turnstile_widget_xhr', {
            sitekey: import.meta.env.VITE_VEC_CF_SITEKEY,
            theme: 'light',
            language: 'de',
            size: 'flexible',
            retry: 'never',
            'error-callback': async (e) => {
                overlay.hide();
                reject(e);
            },
            'callback': (token) => {
                overlay.hide();
                if (token) {
                    resolve(); // captcha solved successfully
                } else {
                    reject(new Error('Unable to obtain pre-clearance'));
                }
            }
        });
    });
};

const CF_MITIGATED_HEADER = 'cf-mitigated';
const CF_MITIGATED_CHALLENGE_VALUE = 'challenge';

export const isCloudflareChallenge = (response: Response): boolean => {
    // return response.url.includes("/user/login");
    return (
        response.status === 403 &&
        response.headers.has(CF_MITIGATED_HEADER) &&
        response.headers.get(CF_MITIGATED_HEADER) === CF_MITIGATED_CHALLENGE_VALUE
    );
};

// Handle Cloudflare challenge if status is 403 and the cf-mitigated header exists
const handleCloudflareChallenge = async (response: Response, url: URL, options: RequestInit): Promise<Response> => {

    if (!isCloudflareChallenge(response)) {
        return response; // No challenge, return the original response
    }

    const store = usePiniaStore();

    if (!store.config.security) {
        throw new Error('Cloudflare challenge not handled because security is disabled in config.');
    }

    await showCaptchaOverlay();

    return fetch(url, options);
}

// devextreme header for separation in the backend
const DEFAULT_HEADERS = {
    "X-Devextreme": "1",
};

// include cookies for authentication
const DEFAULT_CREDENTIALS = "include";

export const fetchBackend = async (endpoint: string, options?: RequestInit): Promise<Response> => {

    const url = new URL(`${import.meta.env.VITE_VEC_BACKEND}${endpoint}`);

    // start xdebug session in development mode and with flag in .env.local
    addXDebugSession(url);

    // Merge default request options with provided options
    const requestOptions: RequestInit = {
        credentials: DEFAULT_CREDENTIALS,
        headers: { ...DEFAULT_HEADERS, ...options?.headers },
        ...options,
    };

    // fetch url
    let response = await fetch(url, requestOptions);

    // handle a possible cloudflare challenge
    try {
        response = await handleCloudflareChallenge(response, url, requestOptions);
    } catch (error) {
        console.error(error);
        Sentry.captureException(error);
        //throw error;
    }

    return response;
};

let batchInserts = [] as Array<Record<string, unknown>>,
    batchUpdates = [] as Array<Record<string, unknown>>,
    batchRemoves = [] as Array<Record<string, unknown>>,
    batchInsertsDeferred: Promise<Record<string, unknown>> | undefined,
    batchUpdatesDeferred: Promise<Record<string, unknown>> | undefined,
    batchRemovesDeferred: Promise<void> | undefined;

const processBatchInserts = (insert: Record<string, unknown>, endpoint: string) => {
    batchInserts.push(insert);
    if (!batchInsertsDeferred) {
        batchInsertsDeferred = new Promise((resolve, reject) => {
            setTimeout(batchInsertSend, 0, endpoint, resolve, reject);
        });
    }
    return batchInsertsDeferred;
};


const processBatchUpdates = (update: Record<string, unknown>, endpoint: string) => {
    batchUpdates.push(update);
    if (!batchUpdatesDeferred) {
        batchUpdatesDeferred = new Promise((resolve, reject) => {
            setTimeout(batchUpdateSend, 0, endpoint, resolve, reject);
        });
    }
    return batchUpdatesDeferred;
};

const processBatchRemoves = (remove: Record<string, unknown>, endpoint: string) => {
    batchRemoves.push(remove);
    if (!batchRemovesDeferred) {
        batchRemovesDeferred = new Promise((resolve, reject) => {
            setTimeout(batchRemoveSend, 0, endpoint, resolve, reject);
        });
    }
    return batchRemovesDeferred;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchInsertSend = (endpoint: string, resolve: any) => {
    const inserts = batchInserts;
        //deferred = batchInsertsDeferred;

    batchInserts = [];
    batchInsertsDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(inserts)
    })
    .then(handleErrors)
    .then((result) => {
        resolve(result);
    });

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchUpdateSend = (endpoint: string, resolve: any) => {
    const updates = batchUpdates;
        //deferred = batchUpdatesDeferred;

    batchUpdates = [];
    batchUpdatesDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(updates)
    })
    .then(handleErrors)
    .then(() => {
        resolve();
    });

};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const batchRemoveSend = (endpoint: string, resolve: any) => {
    const removes = batchRemoves;
        //deferred = batchRemovesDeferred;

    batchRemoves = [];
    batchRemovesDeferred = undefined;

    return fetchBackend(endpoint, {
        method: "POST",
        body: JSON.stringify(removes)
    })
    .then(handleErrors)
    .then(() => {
        resolve();
    });
};

type PropertyFunction<T> = () => T;

interface CRUD {
    create?: string | PropertyFunction<string>;
    read: string;
    update?: string | PropertyFunction<string>;
    destroy?: string | PropertyFunction<string>;
}

const isNotEmpty = (value: string | undefined | null): boolean => {
    return value !== undefined && value !== null && value !== "";
};

export const buildQueryString = (params: { [index: string]: any }):string => {
    return Object.keys(params ?? {})
    .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
};

export const gridDataSource = (crud: CRUD, storeConfig?: Partial<CustomStoreOptions>):DataSource => new DataSource({
    store: new CustomStore({
        key: "id",
        load: (loadOptions: { [index: string]: any }) => {
            let params = "?";
            [
                "skip",
                "take",
                "requireTotalCount",
                "requireGroupCount",
                "sort",
                "filter",
                "totalSummary",
                "group",
                "groupSummary"
            ].forEach((i: string) => {
                if (i in loadOptions && isNotEmpty(loadOptions[i]))
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
            });

            // add possible userData
            // https://js.devexpress.com/Documentation/ApiReference/Data_Layer/CustomStore/LoadOptions/#userData
            params += buildQueryString(loadOptions["userData"]);

            //params = params.slice(0, -1);
            return fetchBackend(`${crud.read}${params}`)
            .then(handleErrors)
            .then((result) => {

                // TODO Future: change backend to use data and totalCount instead of rows and total
                result.data = result.rows || [];
                result.totalCount = result.total || 0;
                result.summary = result.summary || [];

                //groupCount: result.groupCount // if required in requireGroupCount
                delete result.rows;
                delete result.total;
                return result;
            });
        },
        insert: (values) => {
            const endpoint = typeof crud.create === "function" ? crud.create() : crud.create;
            return processBatchInserts(values, endpoint || "");
        },
        update: (key, values) => {
            const endpoint = typeof crud.update === "function" ? crud.update() : crud.update;
            return processBatchUpdates({id: key, ...values}, endpoint || "");
        },
        remove: (key) => {
            const endpoint = typeof crud.destroy === "function" ? crud.destroy() : crud.destroy;
            return processBatchRemoves({id: key}, endpoint || "");
        },
        ...storeConfig
    })
});

const baseStore = ({endpoint, params, key = "rows", config = {}}: { endpoint: string, params?: any, key?: string, config?: any }) => {
    return new CustomStore({
        loadMode: "raw",
        cacheRawData: true,
        load: (loadOptions: { [index: string]: any }) => {
            const queryString = {
                ...params,
                ...loadOptions["userData"]
            }

            return fetchBackend(`${endpoint}${queryString ? "?" + buildQueryString(queryString) : ""}`)
            .then(handleErrors)
            .then((result) => {
                return result[key];
            });
        },
        ...config
    });
};

export const regionStore = baseStore({endpoint: "/index/regions", key: "regions"});
export const firmStore = baseStore({endpoint: "/index/firms", key: "firms"});
export const gkkStore = baseStore({endpoint: "/gkk/index?noExport=1"});
export const personalComboStore = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: true},
    config: {key: "id"}
});
export const groupLeaderComboStore = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false, onlygroupleader: true}
});
export const personalComboStoreProjectLeader = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false, onlywithlogin: true}
});
export const personalComboStoreForGkk = baseStore({
    endpoint: "/personal/combo",
    params: {onlyactive: false}
});
export const personalComboStoreForWorkingTime = baseStore({
    endpoint: "/arbeitszeitaufzeichnung/personalcombo",
    config: {
        cacheRawData: false // reload data on every search
    }
});
export const tarifComboStore = baseStore({
    endpoint: "/tarif/index",
    params: {onlyactive: true, noExport: 1},
    config: {key: "id"}
});
export const tarifRestrictedComboStore = baseStore({
    endpoint: "/tarif/comborestricted",
    config: {key: "id"}
});
export const customerComboStore = baseStore({
    endpoint: "/kunde/combo",
    config: {key: "id"}
});
export const materialGroupComboStore = baseStore({
    endpoint: "/materialgruppe/combo",
    config: {
        insert: (values: any) => {
            const endpoint = "/materialgruppe/create"
            return processBatchInserts(values, endpoint || "");
        }
    }
});
export const materialUnitComboStore = baseStore({
    endpoint: "/materialeinheit/combo"
});
