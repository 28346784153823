import themes from 'devextreme/ui/themes';
import CustomPopup from "@/components/generic/custom-popup.vue";
import {usePiniaStore} from "@/store";
import {fetchBackend} from "@/utils/network";
import {formatDate} from "devextreme/localization";
import notify from "devextreme/ui/notify";
import {createVNode, render} from "vue"

// TODO devextreme 22.1 version
// export const my_notify = (type: "custom" | "error" | "info" | "success" | "warning",
//                           message: string, displayTime = 1000):void => {
//     // using new stack functionality which came with >= 22.1
//     notify({
//         type: type,
//         displayTime: displayTime,
//         message: message,
//         closeOnClick: true,
//         hideOnOutsideClick: false
//     }, {position:"bottom center", direction:"up-push"});
// };
export const my_notify = (type: "custom" | "error" | "info" | "success" | "warning",
                          message: string, displayTime = 1000): void => {
    // for batch notify get position of last toast and place it above
    const top = document.getElementsByClassName("dx-toast-content").length * 40;
    notify({
        position: {
            my: "top center",
            at: "top center",
            of: null,
            offset: "0 +" + top
        },
        message: message,
        closeOnClick: true,
        hideOnOutsideClick: false
    }, type, displayTime);
};

interface CreateCustomPopupParams {
    propsData: {
        childComponent: string,
        childProps?: Record<string, unknown>
        popupTitle?: string,
        [index: string]: any
    },
    targetNode?: HTMLElement | Document,
    targetSelector?: string
}

export const createCustomPopup = ({
                                      propsData,
                                      targetNode = document,
                                      targetSelector = "#custom-popups"
                                  }: CreateCustomPopupParams): void => {
    const { vNode, destroy } = mount(
        CustomPopup, {
            props: propsData,
            target: targetNode.querySelector(targetSelector)
        })
    vNode.component!.props.popupDestroy = destroy
};

// vue3 mount is stolen from https://github.com/pearofducks/mount-vue-component/
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const mount = (component:any, { props, target }:{props:any, target:any}) => {
    // create div in target element, render component in created div
    let vNode = createVNode(component, props);
    let container = document.createElement('div')
    render(vNode, container);
    target.appendChild(container);

    const destroy = () => {
        render(null, container);
        container.remove();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        container = vNode = null
    }

    return { vNode, destroy }
}

export const missionTitle = (name: string, day: string, customer: string, place: string): string => {
    return `${name} - ${formatDate(new Date(day), "dd.MM.yy")} - ${customer} - ${place}`;
};

export const addDaysToDate = (date: Date, days: number): Date => {
    // https://stackoverflow.com/a/563442/156448
    const tmp_date = new Date(date.valueOf());
    tmp_date.setDate(tmp_date.getDate() + days);
    return tmp_date;
};

//export const isWeekend = (day:Date): boolean => day.getDay() % 6 === 0;
export const isSaturday = (day:Date): boolean => day.getDay() === 6;
export const isSunday = (day:Date): boolean => day.getDay() === 0;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: number | undefined;

    return (...args: Parameters<F>): Promise<ReturnType<F>> =>
        new Promise(resolve => {
            if (timeout) {
                window.clearTimeout(timeout)
            }
            timeout = window.setTimeout(() => resolve(func(...args)), waitFor)
        })
}

export const loadConfig = async ():Promise<void> => {
    const response = await fetchBackend("/index/config");
    const result = await response.json();
    if (result.success) {
        const store = usePiniaStore();
        store.config = {
            version: result.version,
            logo: `${import.meta.env.VITE_VEC_BACKEND}${result.logo}`,
            title: result.title,
            security: result.security,
        };
        document.title = result.title;
    }
}

// move given array index from one position to another
// see https://stackoverflow.com/a/49874250
export const array_move = (array: Array<any>, from: number, to: number, on = 1): Array<any> => {
    return array.splice(to, 0, ...array.splice(from, on));
};

export const downloadFile = (url:string): void => {
    let filename = '';
    fetchBackend(url)
    .then(res => {
        const header = res.headers.get('Content-Disposition');
        // we can only read "Content-Disposition" header, if we do not use CORS headers
        // so on dev env we have no filenames
        // use https://vec/ to debug for dev
        if(header) {
            const parts = header!.split(';');
            filename = parts[1].split('=')[1];
        }
        return res.blob();
    })
    .then( blob => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        if(filename) {
            a.download = filename;
        }
        a.click();
        a.remove();
    });
};

type AvailableThemes = "generic.light.compact" | "generic.light";
export const switchTheme = (theme: AvailableThemes = "generic.light.compact"): void => {
    // activates inactive theme from index.html
    themes.current(theme);
    if(theme === "generic.light") {
        // this theme has a larger header
        document.documentElement.style.setProperty("--header-height", "56px");
    } else {
        document.documentElement.style.setProperty("--header-height", "46px");
    }
};

export const getHomepage = (): string => {
    const store = usePiniaStore();
    if(store.user.role === "CUS") {
        return "/home";
    } else if (store.user.role === "GRL") {
        return "/einsatz";
    } else if (store.user.role === "PER") {
        return "/einsatz/report";
    } else {
        return "/einteilung";
    }
}
