import auth from "@/auth";
import router from "@/router";
import {alert} from "devextreme/ui/dialog";
import {getHomepage} from "@/utils/helpers";
import {isCloudflareChallenge} from "@/utils/network";

const handleHttpError = async (response: Response): Promise<void> => {

    if (response.status === 401) {
        await alert("", "Nicht eingelogged!");
        await auth.logOut();
        await router.push({
            path: "/login-form",
            query: { redirect: router.currentRoute.value.path }
        });
        throw new Error("You are logged out");
    }

    if (isCloudflareChallenge(response)) {
        await alert("Sicherheits Challence ist fehlgeschlagen.", "Entschuldigung!");
        throw new Error("Cloudflare challenge not successful");
    }

    if (response.status === 403) {
        await alert("Diese Resource ist leider nicht zugänglich. Sie werden auf die Startseite weitergeleitet.", "Entschuldigung!");
        await router.push(getHomepage());
        throw new Error("No permission to access");
    }

    const text = await response.text();
    await alert(`${response.status} ${response.statusText}, ${text}!`, "Error");
    throw new Error("Server error");

};

const handleJsonResponse = async (json: any): Promise<any> => {
    const title = json.success ? "Information" : "Fehler";
    const message = Array.isArray(json.message)
        ? json.message.join("<br>")
        : json.message || json.msg || "Ein unerwarteter Fehler ist aufgetreten! Bitte den Systemadministrator informieren.";

    await alert(message, title);
    if (json.redirect) {
        await router.push(json.redirect);
    }
    return json;
};

export const handleErrors = async (response: Response): Promise<any> => {
    if (!response.ok) {
        return handleHttpError(response);
    }

    // Handle successful response
    const json = await response.json();
    if (json.message || !json.success) {
        return handleJsonResponse(json);
    }
    return json;
};

// handle errors on simple json results without success or message flags
export const handleMinimalErrors = (response: Response): any => {
    // catch http status errors
    if (!response.ok) {
        return handleHttpError(response);
    }

    // http 200
    // return json by default
    return response.json()
};
